import SDK from './libs/sdk'

const defaultConfig = Object.assign({
  el: undefined,
  loggingEnabled: true,
  className: '',
  playerOptions: {
    hiplayer: {
      defaultClassName: 'hibrid-player',
    },
    videojs: {
      defaultClassName: 'video-js vjs-fluid vjs-fill vjs-big-play-centered',
    },
    mediaelement: {
      defaultClassName: 'mejs__player',
    }
  }
}, window.HibridConfig || {});

export default (config) => new SDK(
  Object.assign(defaultConfig, config)
);
