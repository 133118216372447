import Config from './core/Config';
import ElementGenerator from './core/ElementGenerator';
import Player from './instances/Player'
import Entry from './instances/Entry'
import Factory from './players/Factory';
import Logger from './core/Logger';
import EventBus from './core/EventBus'
import {OnPlayerPlay} from './core/EventBus/Subscribers/OnPlayerPlay';
import {appendElement} from './helpers';
import ScreenGenerator from "./core/ScreenGenerator";
import Livestream from "./instances/Livestream";

export default class Sdk {
    /** @param config {object}*/
    constructor(config) {
        Config.init(config);
        this.elementGenerator = new ElementGenerator(config.el);
        this.playerFactory = new Factory();
        this._registerSubscribers();
    }

    /**
     * @param player_id {string}
     * @return {Sdk}
     */
    player(player_id) {
        /** @type {player}*/
        this._player = new Player(player_id);
        return this
    }

    /**
     * @param video_id {string}
     * @param options {object}
     * @return {*}
     */
    video(video_id, options = {}) {
        return this._createEntry(video_id, 'video', options)
    }

    /**
     * @param playlist_id {string}
     * @param options {object}
     * @return {Sdk}
     */
    playlist(playlist_id, options = {}) {
        return this._createEntry(playlist_id, 'playlist', options)
    }

    source(source) {
        if(!source.type || !['video', 'stream'].includes(source.type))
            source.type = 'video';
        this._source = {
            type: source.type,
            data: {
                type: 'application/x-mpegURL',
                poster: source.poster,
                thumbnail: source.poster,
                title: source.title || '',
                description: source.description || ''
            },
            options: source.options || {}
        }
        if(source.type === 'video') this._source.data.embed_url = source.src
        if(source.type === 'stream') this._source.data.url = source.src
        return this
    }

    livestream(stream_id) {
        this._livestream = new Livestream(stream_id);
        return this
    }

    /**
     * @param callback
     * @return {Promise<T>}
     */
    render(callback) {
        const {_entry, _player, playerFactory, elementGenerator} = this;
        return _entry.fetch()
            .then(() => _player.fetch()
                .then((data) => {
                    const {type} = data;
                    const element = elementGenerator.generate(type);

                    playerFactory.make(type, () => {
                        Logger.info('Player successfully booted. Rendering player...');
                        playerFactory.render(_entry, data, element, callback)
                    })
                })).catch((e) => {
                if (e.code === 203) {
                    // render error message
                    const errorScreen = new ScreenGenerator(e.message)
                    errorScreen.renderWarningPage()
                }
            })
    }

    /**
     * @param callback
     * @return {Promise<T>}
     */
    showStream(callback) {
        const {_livestream, _player, playerFactory, elementGenerator} = this;
        return _livestream.fetch()
            .then(() => _player.fetch()
                .then((data) => {
                    const {type} = data;
                    const element = elementGenerator.generate(type);
                    _livestream.type = 'stream'

                    playerFactory.make(type, () => {
                        Logger.info('Player successfully booted. Rendering player...');
                        playerFactory.render(_livestream, data, element, callback)
                    })
                })).catch((e) => {
                if (e.code === 203) {
                    // render error message
                    const errorScreen = new ScreenGenerator(e.message)
                    errorScreen.renderWarningPage()
                }
            })
    }

    remote(callback) {
        const {_source, _player, playerFactory, elementGenerator} = this;
        return _player.fetch().then((data) => {
            const {type} = data;
            const element = elementGenerator.generate(type);
            playerFactory.make(type, () => {
                Logger.info('Player successfully booted. Rendering player...');
                playerFactory.remote(_source, data, element, callback)
            })
        }).catch(e => {
            if (e.code === 203) {
                // render error message
                const errorScreen = new ScreenGenerator(e.message)
                errorScreen.renderWarningPage()
            }
        })
    }

    /**
     * @param entry_id {string}
     * @param type {string}
     * @param options {object}
     * @return {Sdk}
     * @private
     */
    _createEntry(entry_id, type, options) {
        this._entry = new Entry(entry_id, type, options);
        return this
    }

    /**
     * Register basic subscribers on bus instance
     * @private
     */
    _registerSubscribers() {
        EventBus.on('player.play', new OnPlayerPlay())
    }
}
