import Config from './Config';

/**
 * @class Logger
 */
export default class Logger {
  static info(message) {
    Logger.log(message, 'debug')
  }

  static error(message) {
    Logger.log(message, 'error')
  }

  static log(message, level = 'log') {
    Config.get('loggingEnabled') && console[level](Logger._formatMessage(message))
  }

  static _formatMessage(message) {
    message = typeof message === 'object' ? JSON.stringify(message) : message;
    const time = new Date().toISOString()
      .replace('T', ' ')
      .slice(0, 22);
    return `[${time}]: ${message}`
  }
}
